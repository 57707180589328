import { FormHelperTextProps, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Cleave from "cleave.js";
import { InputWidthType } from "core/types";
import { LockSecurePin } from "ds_legacy/components/Icons/SecurePin";
import Tooltip from "ds_legacy/components/Tooltip";
import { ICON_GREY, WHITE } from "ds_legacy/materials/colors";
import { HorizontalLayout } from "ds_legacy/materials/layouts";
import { dp, inputWidth, margin } from "ds_legacy/materials/metrics";
import { FONT_SIZE_14, FONT_SIZE_16 } from "ds_legacy/materials/typography";
import { useBrowserContext } from "dsl/atoms/BrowserProvider";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import {
  CSSProperties,
  ReactNode,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormElement, FormElementProps, isValid } from "react-forms-state";
import { useLocale } from "reduxentities/hooks";
import { getDelimiter } from "test/utils/data/dates";
import { useTranslations } from "translations";
import { getHelperText } from "../Validation";

export type ConnectedTextInputProps = Omit<FormElementProps, "onChange"> &
  Omit<TextInputFieldProps, "onChange"> &
  Required<Pick<TextInputFieldProps, "elementName">>;

export type ConnectedTextInputWithTooltip = ConnectedTextInputProps & {
  tooltip?: string;
};

export type MaskType = "date" | "iban" | "time";

type InferOnChangeValueType<T extends TextInputFieldProps> =
  T["textInputType"] extends "number" ? number : string;

export type TextInputFieldProps = Pick<
  TextFieldProps,
  | "autoComplete"
  | "autoFocus"
  | "disabled"
  | "fullWidth"
  | "id"
  | "inputProps"
  | "inputRef"
  | "label"
  | "maxRows"
  | "multiline"
  | "onKeyDown"
  | "placeholder"
  | "required"
  | "value"
> & {
  ariaDescribedBy?: string;
  elementName?: string;
  endAdornment?: ReactNode;
  hasCustomValidation?: boolean;
  hide?: boolean;
  inputType?: InputWidthType;
  marginOverride?: string;
  mask?: MaskType;
  onBlur?: () => void;
  onChange?: (value: InferOnChangeValueType<TextInputFieldProps>) => void;
  onClick?: () => void;
  secure?: string;
  startAdornment?: ReactNode;
  style?: TextFieldProps["sx"];
  testId?: string;
  textInputType?: TextFieldProps["type"];
  unit?: string;
  validateOverride?: string;
  validation?: any;
  variant?: "filled" | "outlined" | "standard";
  width?: CSSProperties["width"];
};

/**
 * This prevents changing the value with a scroll wheel, when type is "number".
 * @param inputRef - A ref to the input element.
 * @param textInputType - Determines the input type
 */
function usePreventScrollOnChange(
  inputRef: RefObject<HTMLInputElement>,
  textInputType: TextFieldProps["type"],
) {
  useEffect(() => {
    if (textInputType !== "number") return;

    const inputElement = inputRef.current;
    const handleWheel = (event: WheelEvent) => {
      if (inputElement && inputElement === document.activeElement) {
        event.preventDefault();
      }
    };

    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel, { passive: false });
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, [inputRef, textInputType]);
}

// export for tests
export const TextInputField = ({
  ariaDescribedBy,
  elementName,
  label,
  placeholder = "",
  value,
  onChange = () => {},
  onKeyDown = () => {},
  width,
  validation,
  disabled,
  onClick,
  style,
  required,
  inputType,
  fullWidth = false,
  multiline = false,
  maxRows,
  hide,
  marginOverride,
  textInputType,
  inputProps = {},
  validateOverride,
  autoFocus,
  testId,
  autoComplete,
  inputRef,
  unit,
  hasCustomValidation = false,
  startAdornment,
  endAdornment,
  secure,
  mask,
  id,
  onBlur,
  variant,
}: TextInputFieldProps) => {
  const localRef = useRef<HTMLInputElement>(null);
  const effectiveRef = inputRef || localRef;
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const translations = useTranslations();
  const { isChrome } = useBrowserContext();
  const { isMobile } = useMedia();
  const locale = useLocale();
  const fieldId = `input_text_${id ? id : elementName || ""}`;

  usePreventScrollOnChange(
    effectiveRef as RefObject<HTMLInputElement>,
    textInputType,
  );

  useEffect(() => {
    let cleave: Cleave;
    switch (mask) {
      case "date": {
        const delimiter = getDelimiter(locale);

        cleave = new Cleave(`#${fieldId}`, {
          date: true,
          delimiter,
          datePattern: ["d", "m", "Y"],
          onValueChanged: (e) => onChange(e.target.value),
        });
        break;
      }
      case "time": {
        cleave = new Cleave(`#${fieldId}`, {
          time: true,
          delimiter: ":",
          timePattern: ["h", "m"],
          onValueChanged: (e) => onChange(e.target.value),
        });
        break;
      }
      case "iban":
        {
          cleave = new Cleave(`#${fieldId}`, {
            delimiter: " ",
            blocks: [4, 4, 4, 4, 4, 2],
            onValueChanged: (e) => onChange(e.target.value),
          });
        }
        break;
      default:
        break;
    }

    return () => {
      if (cleave != null) cleave.destroy();
    };
  }, [fieldId, mask, locale]);

  const endAdornmentElement = useMemo(() => {
    let elem;
    const adornmentIconStyle = { fontSize: FONT_SIZE_16 };
    const endAdornmentSxProps = {
      height: "100%",
      paddingBottom: dp(5),
    };

    if (textInputType === "password")
      elem = (
        <IconButton
          size="large"
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          aria-label={
            isPasswordVisible
              ? translations.login.passwordVisibility.hide
              : translations.login.passwordVisibility.show
          }
        >
          {isPasswordVisible ? (
            <EyeOffIcon
              style={{ color: ICON_GREY, ...adornmentIconStyle }}
              size={adornmentIconStyle.fontSize}
            />
          ) : (
            <EyeIcon
              style={{ color: ICON_GREY, ...adornmentIconStyle }}
              size={adornmentIconStyle.fontSize}
            />
          )}
        </IconButton>
      );

    if (secure)
      elem = <LockSecurePin style={adornmentIconStyle} tooltip={secure} />;

    return (
      <HorizontalLayout
        data-testid={`${elementName}_adornment`}
        aligned
        overflow="visible"
      >
        {unit ? (
          <InputAdornment position="end" sx={endAdornmentSxProps}>
            <Typography style={{ fontSize: FONT_SIZE_14 }}>{unit}</Typography>
          </InputAdornment>
        ) : null}
        {endAdornment ? (
          <InputAdornment position="end" sx={endAdornmentSxProps}>
            {endAdornment}
          </InputAdornment>
        ) : null}
        {elem ? (
          <InputAdornment position="end" sx={endAdornmentSxProps}>
            {elem}
          </InputAdornment>
        ) : null}
      </HorizontalLayout>
    );
  }, [textInputType, secure, isPasswordVisible, unit, endAdornment]);

  if (hide) return null;

  const type = (function () {
    if (textInputType !== "password") return textInputType;
    return isPasswordVisible ? "text" : "password";
  })();

  return (
    <TextField
      autoComplete={
        autoComplete != null
          ? autoComplete
          : isChrome
          ? `new-${elementName || "input"}`
          : "off"
      }
      autoFocus={autoFocus}
      disabled={disabled}
      error={!isValid(validation)}
      FormHelperTextProps={{
        ...({
          "data-testid": `${elementName}-form-helper-text`,
        } as FormHelperTextProps),
      }}
      fullWidth={fullWidth}
      id={fieldId}
      helperText={getHelperText({
        hasCustomValidation,
        translations,
        validateOverride,
        validation,
      })}
      InputProps={{
        endAdornment: endAdornmentElement,
        startAdornment: startAdornment ? (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ) : null,
        sx: {
          "&.MuiOutlinedInput-root": {
            backgroundColor: WHITE,
          },
        },
      }}
      InputLabelProps={{
        style: { fontSize: isMobile ? FONT_SIZE_16 : FONT_SIZE_14 },
      }}
      inputProps={{
        "aria-describedby": ariaDescribedBy,
        ...inputProps,
        style: { fontSize: isMobile ? FONT_SIZE_16 : FONT_SIZE_14 },
        ...{ "data-testid": testId || elementName },
      }}
      inputRef={effectiveRef}
      label={label}
      required={required}
      maxRows={maxRows}
      multiline={multiline}
      name={elementName}
      onChange={(e) => {
        let newValue: string | null | number = e.target.value;

        if (textInputType === "number") {
          newValue = newValue === "" ? null : Number(newValue);
        }

        onChange(newValue as InferOnChangeValueType<TextInputFieldProps>);
      }}
      onClick={onClick}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      size="small"
      sx={{
        margin: marginOverride || margin(1, 2),
        width: width || inputWidth(inputType),
        fontSize: FONT_SIZE_14,
        ...style,
      }}
      type={type}
      value={value === null ? "" : value}
      variant={variant ?? "standard"}
    />
  );
};

const ConnectedTextInputField =
  FormElement()<ConnectedTextInputProps>(TextInputField);

export default function ConnectedTextInputFieldWithTooltip(
  props: ConnectedTextInputWithTooltip,
) {
  return (
    <Tooltip title={props.tooltip}>
      <ConnectedTextInputField {...props} />
    </Tooltip>
  );
}
